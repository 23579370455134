.App {
  margin-top: 15px;
}

.App .navbar-brand {
  font-weight: bold;
}

.App .form-rating {
  background-color: white;
  padding-top: 6px;
  padding-left: 8px;
  padding-bottom: 6px;
  border-color: hsl(0,0%,80%);
  border-top-color: rgb(204, 204, 204);
  border-right-color: rgb(204, 204, 204);
  border-bottom-color: rgb(204, 204, 204);
  border-left-color: rgb(204, 204, 204);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
}

.App .form-rating .low, .App .table-rating .low {
  color: #f58686;
}

.App .form-rating .medium, .App .table-rating .medium {
  color: #e4e27c;
}

.App .form-rating .high, .App .table-rating .high {
  color: #7de47c;
}

.App .navbar li.profileImage {
  padding-left: 10px;
}

.App .navbar li.profileImage a {
  padding: 0px !important;
  height: 50px;
}

.App .navbar img.profileImage  {
  margin-top: 3px;
  width: 43px;
  vertical-align: middle;
}

.App .footer  {
  margin-top: 30px;
  background-color: transparent;
  border: none;
  font-size: 12px;
  position: relative;
  z-index: 0;
}

.App .footer .container  {
  display: flex;
  justify-content: center;
}

.App .footer .navbar-nav>li>a {
  color: black;
}

.App .btn-toolbar {
  position: relative;
  z-index: 10;
}

.App button.inline-text-link {
  font-size: 12px;
  padding: 0px;
  border: none;
  vertical-align: inherit;
}