.sourcing .stage-disabled {
  opacity: 0.6;
}

.sourcing .container {
  width: 1130px;
}

.tenderSeatsCardList {
  margin-top: 8px;
}

.sourcing .panel-heading button {
  padding-top: 2px;
  padding-left: 7px;
  height: 24px;
  width: 30px;
}

.sourcing .panel-heading button.plusButton {
  margin-right: 7px;
}

.sourcing .panel-heading button.startTenderingButton { 
  margin-right: 7px;
  width: 130px;
}

.sourcing .panel-heading button.uploadTenderDocumentsButton { 
  margin-right: 7px;
  width: 200px;
}

.sourcing .panel-heading button.startContractingButton {
  margin-right: 7px;
  width: 140px;
}

.sourcing .panel-body h2 {
  margin-top: 5px;
}

.sourcing .panel-body h3 {
  margin-top: 10px;
}

.sourcing .field {
  margin-bottom: 5px;
}

.sourcing .field .title {
  font-weight: bold;
  margin-right: 5px;
}

.sourcing .sourcingDates .container {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}

.sourcing .sourcingDates .container .title, .sourcing .sourcingDates .container input {
  text-align: center;
}

.sourcing .sourcingDates .container .not-future input {
   background-color: #fbf8e3; /* bootstrap "warning" */
}

.sourcing .cards {
  display: flex;
  justify-content: space-evenly;
  flex-flow: row wrap;
  /* width: 100%;
  margin: 0px;
  margin-bottom: 10px; */
  /* background-color: lightgreen; */
}

.sourcing .card {
  background-color: #d9edf7; /* bootstrap "info" */
  border: 2px solid #2176ad;
  border-radius: 10px;
  padding: 5px;
  padding-left: 10px;
  padding-bottom: 7px;
  margin-bottom: 10px;
  width: 155px;
  text-align: center;
  min-height: 50px;
}

.sourcing .card.emptySeat {
  background-color: #f5f5f5; /* bootstrap "defaul" */
  border-color: #dddddd;
}

.sourcing .card.invited {
  background-color: #fbf8e3; /* bootstrap "warning" */
  border-color: #d0ce25;
}

.sourcing .card.inviteRejected {
  background-color: #f2dedd; /* bootstrap "danger" */
  border-color: #ad2121;
}

.sourcing .card.inviteAccepted, .sourcing .card.inviteSelected {
  background-color: #dff0d7; /* bootstrap "success" */
  border-color: #73AD21;
}

.sourcing .card.invitePreferred {
  box-shadow: 0 0 4px 1px #f5ca3a;
}

.sourcing .no-pros {
  text-align: center;
}
