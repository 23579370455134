.sourcingProfessionalProfile-modal {
  width: 1200px;
}

.sourcingProfessionalProfile-modal .container {
  margin-top: 12px;
}

.sourcingProfessionalProfile-modal .titleLogo {
  margin-left: 16px;
}

.sourcingProfessionalProfile-modal .titleLogo img {
  width: 20px;
  margin-right: 16px;
  vertical-align: top;
}

.sourcingProfessionalProfile-modal .titleEdit {
  margin-right: 12px;
}

.sourcingProfessionalProfile-modal .titleRating .rating-stars {
  margin-right: 12px;
}

.sourcingProfessionalProfile-modal .titleRating .rating-stars {
  font-size: 10px; /* Any bigger and this makes the title bar bigger */
}

.sourcingProfessionalProfile-modal .contact .field {
  margin-bottom: 16px;
}

.sourcingProfessionalProfile-modal .contact .fieldName {
  margin-bottom: 8px;
  font-weight: bold;
}

.sourcingProfessionalProfile-modal .label {
  margin-right: 12px;
  line-height: 2.5;
  font-size: 12px;
}

.sourcingProfessionalProfile-modal .summary .row {
  margin-bottom: 16px;
}

.sourcingProfessionalProfile-modal pre {
  white-space: pre-line;
  word-break: normal;
}

.sourcingProfessionalProfile-modal  .porfolioImages img {
  height: 360px;
  margin-right: 16px;
  margin-bottom: 16px;
}
