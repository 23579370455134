.inviter-modal {
  width: 90%
}

.inviter-modal .inviteProfessionalCard {
  background-color: #f5f5f5; /* bootstrap "defaul" */
  border: 2px solid #dddddd;;
  border-radius: 10px;
  padding: 5px;
  padding-left: 10px;
  padding-bottom: 7px;
  margin-bottom: 10px;
}

/* HACK! Tidy up with class names -- gets the star aligned center */
.inviter-modal .inviteProfessionalCard .col-md-1 {
  text-align: center;
}

/* HACK! Tidy up with class names -- puts spaces between the elements on the card */
.inviter-modal .inviteProfessionalCard .row .row {
  margin-bottom: 8px;
}

/* HACK! Tidy up with class names -- pushes the content of the card down a bit */
.inviter-modal .inviteProfessionalCard > .row > .col-md-11  {
  margin-top: 4px;
}

/* HACK! Tidy up with class names -- spaces out the labels */
.inviter-modal .inviteProfessionalCard .row .row .label {
  margin-right: 5px;
}

.inviter-modal .inviteProfessionalCard.selected {
  background-color: #fbf8e3; /* bootstrap "warning" */
  border-color: #d0ce25;
}

.inviter-modal .inviteProfessionalCard .selectionToggle {
  margin-left: -30px;
  font-size: 40px;
}

.inviter-modal .emptyCard {
  text-align: center;
  font-weight: bold;
}