.privacyPolicy .page-header, .privacyPolicy h1 {
  margin-top: 0px;
}
.privacyPolicy .inShort {
  font-style: italic;
}
.privacyPolicy .important {
  font-weight: bold;
}
.privacyPolicy ul > li {
  margin-bottom: 8px;
}
.privacyPolicy pre {
  word-wrap: break-word;
}
.privacyPolicy .credits {
  width: 100%;
  text-align: center;
  margin-top: 25px;
  font-style: italic;
}