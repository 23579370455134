@media all and (min-width: 480px) {
  .SignupValidation {
    padding: 60px 0;
  }

  .SignupValidation form {
    margin: 0 auto;
    max-width: 320px;
  }
}

.SignupValidation form span.help-block {
  font-size: 14px;
  padding-bottom: 10px;
  color: #777;
}
