@import url('https://fonts.googleapis.com/css?family=Open+Sans|Rubik&display=swap');

body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  color: #333;
  background-color: #f0bd88;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6, .navbar {
  font-family: "Rubik", serif;
}

h2 {
  font-size: 25px;
}

h3 {
  font-size: 22px;
}

/* Getting the nav bar text size to match the logo */
.App .navbar {
  font-size: 18px;
}

/* Getting the logo to align some what vertically centrally on the left vs the rest of the nav bar text */
.App .navbar .navbar-brand {
  padding: 8px 15px;
}

.App .navbar img.logo {
  height: 30px;
  display: inline;
}

/* A hack to get the studio name centered */
.App .studioName li {
  margin-left: 150px;
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  width: 500px;
}

/* A hack to stop the studio name having an active dark grey background */
.navbar-default .studioName > .active > a {
  background-color: transparent !important;
}

/* Singifying error state on form controls */
.form-control.value-good {
  border-color: lightgreen !important;
}

.form-control.value-ok {
  border-color: orange !important;
}

.form-control.value-bad {
  border-color: lightcoral !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
