div.MyContacts table {
  background-color: white;
}

div.MyContacts .header-class-company {
  width: 330px;
}

div.MyContacts .header-class-type {
  width: 190px;
}

div.MyContacts .header-class-name {
  width: 280px;
}

div.MyContacts .header-class-status {
  width: 150px;
}

div.MyContacts .column-tags .badge {
  margin-right: 5px;
}

div.MyContacts th label {
  display: block;
  margin-top: 8px;
}

select.form-control, textarea.form-control, input.form-control {
  font-size: 13px;
}

div.MyContacts .badge {
  margin-bottom: 3px;
}

div.MyContacts .btn-toolbar {
  margin-bottom: 20px;
}
