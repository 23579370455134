.rating-stars .low {
  color: #f58686;
}

.rating-stars .medium {
  color: #e4e27c;
}

.rating-stars .high {
  color: #7de47c;
}
