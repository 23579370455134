h3.projectStage {
  font-size: 26px;
}

.project .panel-body .row, .project .panel-body .fieldList {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0px;
  margin-bottom: 10px;
  /* background-color: lightgreen; */
}

.project .panel-body .row.buttons {
  margin-top: -5px;
  margin-bottom: 0px;
}

.project .left {
  width: 100%;
  /* background-color: lightyellow; */
}

.project .right {
  margin-left: 30px;
  width: 500px;
  /* background-color: lightblue; */
}

.project .field {
  margin-bottom: 5px;
}

.project .field .title {
  font-weight: bold;
  margin-right: 5px;
}

.project .panel-body .fieldListTitle {
  margin-bottom: 13px;
}

.project .panel-body .fieldListContainer {
  display: flex;
  justify-content: center;
}

.project .panel-body .fieldList {
  justify-content: space-evenly;
  flex-flow: row wrap;
  width: 950px;
}

.project .card {
  background-color: #d9edf7; /* bootstrap "info" */
  border: 2px solid #2176ad;
  border-radius: 10px;
  padding: 5px;
  padding-left: 10px;
  padding-bottom: 7px;
  margin-bottom: 10px;
  width: 300px;
  text-align: center;
}

.project .card.empty {
  background-color: #f5f5f5; /* bootstrap "defaul" */
  border-color: #dddddd;
}

.project .card.sourcing-vacant {
  background-color: #f2dedd; /* bootstrap "danger" */
  border-color: #ad2121;
}

.project .card.sourcing-underway {
  background-color: #fbf8e3; /* bootstrap "warning" */
  border-color: #d0ce25;
}

.project .card.sourcing-complete {
  background-color: #dff0d7; /* bootstrap "success" */
  border-color: #73AD21;
}

.project .card.cardArchitect .name {
  margin-left: 6px;
}

.project .card.cardArchitect.LEAD, .project .card.cardArchitect.OTHER {
  background-color: #dff0d7; /* bootstrap "success" */
  border-color: #73AD21;
}

.architectListGroup .list-group-item {
  height: 55px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.architectListGroup .list-group-item span.text {
  width: 84%;
}

.architectListGroup .list-group-item span.role {
  margin-left: 12px;
  font-style: italic;
}
