@import url(https://fonts.googleapis.com/css?family=Open+Sans|Rubik&display=swap);
body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  color: #333;
  background-color: #f0bd88;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6, .navbar {
  font-family: "Rubik", serif;
}

h2 {
  font-size: 25px;
}

h3 {
  font-size: 22px;
}

/* Getting the nav bar text size to match the logo */
.App .navbar {
  font-size: 18px;
}

/* Getting the logo to align some what vertically centrally on the left vs the rest of the nav bar text */
.App .navbar .navbar-brand {
  padding: 8px 15px;
}

.App .navbar img.logo {
  height: 30px;
  display: inline;
}

/* A hack to get the studio name centered */
.App .studioName li {
  margin-left: 150px;
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  width: 500px;
}

/* A hack to stop the studio name having an active dark grey background */
.navbar-default .studioName > .active > a {
  background-color: transparent !important;
}

/* Singifying error state on form controls */
.form-control.value-good {
  border-color: lightgreen !important;
}

.form-control.value-ok {
  border-color: orange !important;
}

.form-control.value-bad {
  border-color: lightcoral !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

img.profileImage {
    width: 43px;
}

img.profileImage.unknown {
    opacity: 0.5;
}

.App {
  margin-top: 15px;
}

.App .navbar-brand {
  font-weight: bold;
}

.App .form-rating {
  background-color: white;
  padding-top: 6px;
  padding-left: 8px;
  padding-bottom: 6px;
  border-color: hsl(0,0%,80%);
  border-top-color: rgb(204, 204, 204);
  border-right-color: rgb(204, 204, 204);
  border-bottom-color: rgb(204, 204, 204);
  border-left-color: rgb(204, 204, 204);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
}

.App .form-rating .low, .App .table-rating .low {
  color: #f58686;
}

.App .form-rating .medium, .App .table-rating .medium {
  color: #e4e27c;
}

.App .form-rating .high, .App .table-rating .high {
  color: #7de47c;
}

.App .navbar li.profileImage {
  padding-left: 10px;
}

.App .navbar li.profileImage a {
  padding: 0px !important;
  height: 50px;
}

.App .navbar img.profileImage  {
  margin-top: 3px;
  width: 43px;
  vertical-align: middle;
}

.App .footer  {
  margin-top: 30px;
  background-color: transparent;
  border: none;
  font-size: 12px;
  position: relative;
  z-index: 0;
}

.App .footer .container  {
  display: flex;
  justify-content: center;
}

.App .footer .navbar-nav>li>a {
  color: black;
}

.App .btn-toolbar {
  position: relative;
  z-index: 10;
}

.App button.inline-text-link {
  font-size: 12px;
  padding: 0px;
  border: none;
  vertical-align: inherit;
}
.WeaverRoute {
  background-color: #d2c7ff;
}

.Home .lander {
    padding: 80px 0;
    text-align: center;
  }
  
  .Home .lander h1 {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
  }
  
  .Home .lander p {
    color: #999;
  }
  
.privacyPolicy .page-header, .privacyPolicy h1 {
  margin-top: 0px;
}
.privacyPolicy .inShort {
  font-style: italic;
}
.privacyPolicy .important {
  font-weight: bold;
}
.privacyPolicy ul > li {
  margin-bottom: 8px;
}
.privacyPolicy pre {
  word-wrap: break-word;
}
.privacyPolicy .credits {
  width: 100%;
  text-align: center;
  margin-top: 25px;
  font-style: italic;
}
.LoaderButton .spinning.glyphicon {
    margin-right: 7px;
    top: 2px;
    -webkit-animation: spin 1s infinite linear;
            animation: spin 1s infinite linear;
}
@-webkit-keyframes spin {
    from { -webkit-transform: scale(1) rotate(0deg); transform: scale(1) rotate(0deg); }
    to { -webkit-transform: scale(1) rotate(360deg); transform: scale(1) rotate(360deg); }
}
@keyframes spin {
    from { -webkit-transform: scale(1) rotate(0deg); transform: scale(1) rotate(0deg); }
    to { -webkit-transform: scale(1) rotate(360deg); transform: scale(1) rotate(360deg); }
}

.form-control.password-input {
    border: 3px solid #ccc;
    color: #616161;
    outline: 0;
    padding: 7px;
    font-size: 1.0em;
    min-width: 250px;
    border-radius: 5px;
    background: url('/password-lock.png') no-repeat 10px 8px;
    background-color: white;
    background-size: 17px 17px;
    padding-left: 35px;
}

@media all and (min-width: 480px) {
  .Signup {
    padding: 60px 0;
  }

  .Signup form {
    margin: 0 auto;
    max-width: 320px;
  }
}

.Signup form span.help-block {
  font-size: 14px;
  padding-bottom: 10px;
  color: #999;
}

@media all and (min-width: 480px) {
  .SignupValidation {
    padding: 60px 0;
  }

  .SignupValidation form {
    margin: 0 auto;
    max-width: 320px;
  }
}

.SignupValidation form span.help-block {
  font-size: 14px;
  padding-bottom: 10px;
  color: #777;
}

@media all and (min-width: 480px) {
    .Login {
      padding: 60px 0;
    }
  
    .Login form {
      margin: 0 auto;
      max-width: 320px;
    }
}

.Login form .extras {
  margin-bottom: 12px;
  text-align: center;
}

@media all and (min-width: 480px) {
  .ResetPassword {
    padding: 60px 0;
  }

  .ResetPassword form {
    margin: 0 auto;
    max-width: 320px;
  }
}

.profile-image-select {
    width: 120px;
}

.Profile .formGroupWrapper {
  display: flex;
}

.Profile .formGroupWrapper > * {
  width: 100%;
  padding-right: 20px;
}

.Profile div.profileImage {
  width: 180px;
}

.Profile .isDebugging {
  width: 180px;
}

.Profile .hasWeaverTools button {
  margin-top: 10px;
}

.loader {
  color: #ffffff;
  font-size: 20px;
  margin: 100px auto;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  -webkit-animation: load4 1.3s infinite linear;
  animation: load4 1.3s infinite linear;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}
@-webkit-keyframes load4 {
  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
  }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
  }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
  }
  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
  }
}
@keyframes load4 {
  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
  }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
  }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
  }
  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
  }
}

.form-rating-filter {
    border-style: none !important;
    margin-top: 5px;
    margin-bottom: 2px;
    padding-left: 0px !important;
}

div.MyContacts table {
  background-color: white;
}

div.MyContacts .header-class-company {
  width: 330px;
}

div.MyContacts .header-class-type {
  width: 190px;
}

div.MyContacts .header-class-name {
  width: 280px;
}

div.MyContacts .header-class-status {
  width: 150px;
}

div.MyContacts .column-tags .badge {
  margin-right: 5px;
}

div.MyContacts th label {
  display: block;
  margin-top: 8px;
}

select.form-control, textarea.form-control, input.form-control {
  font-size: 13px;
}

div.MyContacts .badge {
  margin-bottom: 3px;
}

div.MyContacts .btn-toolbar {
  margin-bottom: 20px;
}

.MyContact .btn-toolbar {
  margin-top: 25px;
  margin-bottom: 20px;
}

.MyContact .formGroupWrapper {
  display: flex;
  align-items: stretch;
}

.MyContact .formGroupWrapper > * {
  width: 100%;
  padding-right: 20px;
}

.MyContact .formGroupWrapper :last-child {
  padding-right: 0px;
}

.MyContact .form-select {
  font-size: 18px;
}

.MyContact .label {
  margin-top: 3px;
  padding-top: 0.4em;
  padding-right: 0.6em !important;
  padding-bottom: 0.4em;
  padding-left: 0.6em;
}

.MyContact .react-datepicker-wrapper input {
  font-size: 18px;
  height: 46px;
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.3333333;
  border-radius: 6px;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
}


.addressListGroup .list-group-item {
  height: 55px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.addressListGroup .list-group-item span {
  width: 84%;
}

h3.projectStage {
  font-size: 26px;
}

.project .panel-body .row, .project .panel-body .fieldList {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0px;
  margin-bottom: 10px;
  /* background-color: lightgreen; */
}

.project .panel-body .row.buttons {
  margin-top: -5px;
  margin-bottom: 0px;
}

.project .left {
  width: 100%;
  /* background-color: lightyellow; */
}

.project .right {
  margin-left: 30px;
  width: 500px;
  /* background-color: lightblue; */
}

.project .field {
  margin-bottom: 5px;
}

.project .field .title {
  font-weight: bold;
  margin-right: 5px;
}

.project .panel-body .fieldListTitle {
  margin-bottom: 13px;
}

.project .panel-body .fieldListContainer {
  display: flex;
  justify-content: center;
}

.project .panel-body .fieldList {
  justify-content: space-evenly;
  flex-flow: row wrap;
  width: 950px;
}

.project .card {
  background-color: #d9edf7; /* bootstrap "info" */
  border: 2px solid #2176ad;
  border-radius: 10px;
  padding: 5px;
  padding-left: 10px;
  padding-bottom: 7px;
  margin-bottom: 10px;
  width: 300px;
  text-align: center;
}

.project .card.empty {
  background-color: #f5f5f5; /* bootstrap "defaul" */
  border-color: #dddddd;
}

.project .card.sourcing-vacant {
  background-color: #f2dedd; /* bootstrap "danger" */
  border-color: #ad2121;
}

.project .card.sourcing-underway {
  background-color: #fbf8e3; /* bootstrap "warning" */
  border-color: #d0ce25;
}

.project .card.sourcing-complete {
  background-color: #dff0d7; /* bootstrap "success" */
  border-color: #73AD21;
}

.project .card.cardArchitect .name {
  margin-left: 6px;
}

.project .card.cardArchitect.LEAD, .project .card.cardArchitect.OTHER {
  background-color: #dff0d7; /* bootstrap "success" */
  border-color: #73AD21;
}

.architectListGroup .list-group-item {
  height: 55px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.architectListGroup .list-group-item span.text {
  width: 84%;
}

.architectListGroup .list-group-item span.role {
  margin-left: 12px;
  font-style: italic;
}

.rating-stars .low {
  color: #f58686;
}

.rating-stars .medium {
  color: #e4e27c;
}

.rating-stars .high {
  color: #7de47c;
}

.inviter-modal {
  width: 90%
}

.inviter-modal .inviteProfessionalCard {
  background-color: #f5f5f5; /* bootstrap "defaul" */
  border: 2px solid #dddddd;;
  border-radius: 10px;
  padding: 5px;
  padding-left: 10px;
  padding-bottom: 7px;
  margin-bottom: 10px;
}

/* HACK! Tidy up with class names -- gets the star aligned center */
.inviter-modal .inviteProfessionalCard .col-md-1 {
  text-align: center;
}

/* HACK! Tidy up with class names -- puts spaces between the elements on the card */
.inviter-modal .inviteProfessionalCard .row .row {
  margin-bottom: 8px;
}

/* HACK! Tidy up with class names -- pushes the content of the card down a bit */
.inviter-modal .inviteProfessionalCard > .row > .col-md-11  {
  margin-top: 4px;
}

/* HACK! Tidy up with class names -- spaces out the labels */
.inviter-modal .inviteProfessionalCard .row .row .label {
  margin-right: 5px;
}

.inviter-modal .inviteProfessionalCard.selected {
  background-color: #fbf8e3; /* bootstrap "warning" */
  border-color: #d0ce25;
}

.inviter-modal .inviteProfessionalCard .selectionToggle {
  margin-left: -30px;
  font-size: 40px;
}

.inviter-modal .emptyCard {
  text-align: center;
  font-weight: bold;
}
.sourcingProfessionalProfile-modal {
  width: 1200px;
}

.sourcingProfessionalProfile-modal .container {
  margin-top: 12px;
}

.sourcingProfessionalProfile-modal .titleLogo {
  margin-left: 16px;
}

.sourcingProfessionalProfile-modal .titleLogo img {
  width: 20px;
  margin-right: 16px;
  vertical-align: top;
}

.sourcingProfessionalProfile-modal .titleEdit {
  margin-right: 12px;
}

.sourcingProfessionalProfile-modal .titleRating .rating-stars {
  margin-right: 12px;
}

.sourcingProfessionalProfile-modal .titleRating .rating-stars {
  font-size: 10px; /* Any bigger and this makes the title bar bigger */
}

.sourcingProfessionalProfile-modal .contact .field {
  margin-bottom: 16px;
}

.sourcingProfessionalProfile-modal .contact .fieldName {
  margin-bottom: 8px;
  font-weight: bold;
}

.sourcingProfessionalProfile-modal .label {
  margin-right: 12px;
  line-height: 2.5;
  font-size: 12px;
}

.sourcingProfessionalProfile-modal .summary .row {
  margin-bottom: 16px;
}

.sourcingProfessionalProfile-modal pre {
  white-space: pre-line;
  word-break: normal;
}

.sourcingProfessionalProfile-modal  .porfolioImages img {
  height: 360px;
  margin-right: 16px;
  margin-bottom: 16px;
}

.sourcing .stage-disabled {
  opacity: 0.6;
}

.sourcing .container {
  width: 1130px;
}

.tenderSeatsCardList {
  margin-top: 8px;
}

.sourcing .panel-heading button {
  padding-top: 2px;
  padding-left: 7px;
  height: 24px;
  width: 30px;
}

.sourcing .panel-heading button.plusButton {
  margin-right: 7px;
}

.sourcing .panel-heading button.startTenderingButton { 
  margin-right: 7px;
  width: 130px;
}

.sourcing .panel-heading button.uploadTenderDocumentsButton { 
  margin-right: 7px;
  width: 200px;
}

.sourcing .panel-heading button.startContractingButton {
  margin-right: 7px;
  width: 140px;
}

.sourcing .panel-body h2 {
  margin-top: 5px;
}

.sourcing .panel-body h3 {
  margin-top: 10px;
}

.sourcing .field {
  margin-bottom: 5px;
}

.sourcing .field .title {
  font-weight: bold;
  margin-right: 5px;
}

.sourcing .sourcingDates .container {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}

.sourcing .sourcingDates .container .title, .sourcing .sourcingDates .container input {
  text-align: center;
}

.sourcing .sourcingDates .container .not-future input {
   background-color: #fbf8e3; /* bootstrap "warning" */
}

.sourcing .cards {
  display: flex;
  justify-content: space-evenly;
  flex-flow: row wrap;
  /* width: 100%;
  margin: 0px;
  margin-bottom: 10px; */
  /* background-color: lightgreen; */
}

.sourcing .card {
  background-color: #d9edf7; /* bootstrap "info" */
  border: 2px solid #2176ad;
  border-radius: 10px;
  padding: 5px;
  padding-left: 10px;
  padding-bottom: 7px;
  margin-bottom: 10px;
  width: 155px;
  text-align: center;
  min-height: 50px;
}

.sourcing .card.emptySeat {
  background-color: #f5f5f5; /* bootstrap "defaul" */
  border-color: #dddddd;
}

.sourcing .card.invited {
  background-color: #fbf8e3; /* bootstrap "warning" */
  border-color: #d0ce25;
}

.sourcing .card.inviteRejected {
  background-color: #f2dedd; /* bootstrap "danger" */
  border-color: #ad2121;
}

.sourcing .card.inviteAccepted, .sourcing .card.inviteSelected {
  background-color: #dff0d7; /* bootstrap "success" */
  border-color: #73AD21;
}

.sourcing .card.invitePreferred {
  box-shadow: 0 0 4px 1px #f5ca3a;
}

.sourcing .no-pros {
  text-align: center;
}

.Home .lander {
    padding: 80px 0;
    text-align: center;
  }
  
  .Home .lander h1 {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
  }
  
  .Home .lander p {
    color: #084008;
  }
  
.badge-success {
  background-color: #468847;
}

.syncSupplyProfessionals .label {
  margin-right: 5px;
}

.NotFound {
    padding-top: 100px;
    text-align: center;
}


.contact-us-modal p {
  text-align: center;
}
