.Profile .formGroupWrapper {
  display: flex;
}

.Profile .formGroupWrapper > * {
  width: 100%;
  padding-right: 20px;
}

.Profile div.profileImage {
  width: 180px;
}

.Profile .isDebugging {
  width: 180px;
}

.Profile .hasWeaverTools button {
  margin-top: 10px;
}
