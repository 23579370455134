.addressListGroup .list-group-item {
  height: 55px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.addressListGroup .list-group-item span {
  width: 84%;
}
