.MyContact .btn-toolbar {
  margin-top: 25px;
  margin-bottom: 20px;
}

.MyContact .formGroupWrapper {
  display: flex;
  align-items: stretch;
}

.MyContact .formGroupWrapper > * {
  width: 100%;
  padding-right: 20px;
}

.MyContact .formGroupWrapper :last-child {
  padding-right: 0px;
}

.MyContact .form-select {
  font-size: 18px;
}

.MyContact .label {
  margin-top: 3px;
  padding-top: 0.4em;
  padding-right: 0.6em !important;
  padding-bottom: 0.4em;
  padding-left: 0.6em;
}

.MyContact .react-datepicker-wrapper input {
  font-size: 18px;
  height: 46px;
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.3333333;
  border-radius: 6px;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
}

